<template>
  <v-app>
    <confirm ref="confirm"></confirm>
    <template v-for="toast of $store.state.toasts.items">
      <v-snackbar :key="toast.id"
        v-model="toast.model"
        :timeout="toast.ttl"
        :vertical="false"
        :top="true"
        :color="toast.type"
      >
        {{toast.msg}}
        <v-btn
          color="indigo"
          text
          @click="toast.model = false"
        >
          Schließen
        </v-btn>
      </v-snackbar>
    </template>
    <router-view v-if="ui.initFinished" style="padding-bottom:70px"/>
    <v-footer
    color="grey lighten-4"
    app
    padless
    >
      <v-row
        justify="center"
        no-gutters
      >
        <v-col
          class="grey lighten-3 py-4 pr-4 text-right"
          cols="12"
        >
          <v-btn href="https://www.bavheute.de/datenschutz/" text small class="mr-1">Datenschutz</v-btn><v-btn href="https://www.stuttgarter.de/impressum" text small>Impressum</v-btn>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import Confirm from '@/components/Confirm.vue'
import Vue from 'vue'

export default {
  name: 'app',
  data: function() {
    return {
      state: this.$store.state,
      ui: {
        initFinished: false
      }
    }
  },
  components: {
    Confirm
  },
  async mounted() {
    window.bus = new Vue({})
    await this.init()
    this.setInterceptor()
    this.ui.initFinished = true
    this.$root.$confirm = this.$refs.confirm.open
  },
  methods: {
    async init() {
      let d = new Date()
      if (d.getMonth() == 11 && d.getDate() == 24) this.$store.startSnow()
      let isSession = this.checkIfSession()
      if (isSession) {
        console.log("issession")
        if (this.$route.name == 'home') {
          this.$router.push({name: 'message'})
        }
        return
      }
      let user = this.$ls.get('user')
      if (user && user.token) {
        if (await this.checkToken(user.token)) {
          this.$router.push({name: 'message'})
        }
      }
    },
    setInterceptor() {
      
      this.$http.interceptors.response.use((response) => {
        return response
      }, (error) => {
        if (Number(error.response.status) >= 401 && Number(error.response.status) < 500) {
          console.log("rouuute")
          this.$store.logout()
          if (this.$route.name !== 'home' && this.$route.name !== 'activate' && this.$route.name !== 'confirmresetpassword') this.$router.push({ name: 'home' })
          
        }
        return Promise.reject(error)
      })
      
    },
    checkLS() {
      let user = this.$ls.get('user')
      if (user && user.token) {
        return true
      }
      return false
    },
    checkIfSession() {
      let user = this.$lsSession.get('user')
      if (user && user.token) {
        console.log(user)
        this.$store.setUserObject(user)
        this.$store.setHttpToken()
        return true
      }
      return false
    },
    async checkToken(token) {
      let model = {
        token
      }
      try {
        let result = await this.$http.post(this.state.app.apiUrl + 'api/login', model)
        if (result.data) {
          this.$store.setUserObject(result.data)
        }
        this.$store.setHttpToken()
        this.$store.saveTokenToLocalStorage()
        this.$store.saveTokenToSession()
        return true
      } catch(err) {
        console.log(err.response)
        this.$store.logout()
        this.$router.push({name: 'home'})
        return
      }
    }
  }
}
</script>

<style lang="stylus">
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition-duration: 0.5s;
  transition-property: height, opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  transform: translate(2em, 0);
}

.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  transform: translate(-2em, 0);
}
</style>
