<template>
  <v-container class="home">
    <v-row align="center" align-content="center" justify="center">
      <v-col cols="12" xl="4" lg="5" md="8" sm="10" xs="12" class="text-center">
        <v-card class="pa-6">
          <span @click="$store.egc()">Login</span>
          <v-divider class="mt-2 mb-8" />
          <v-row no-gutters>
            <v-col cols="12">
              <v-text-field
                label="E-Mail"
                v-model="model.email"
                suffix="@stuttgarter.de"
                filled
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" justify-content>
              <v-text-field
                filled
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show1 ? 'text' : 'password'"
                label="Passwort"
                v-model="model.password"
                @click:append="show1 = !show1"
                @keyup.enter="login()"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters align="center" align-content="center" justify="center">
            <v-col cols="6" justify-content>
              <v-checkbox
                :hide-details="true"
                v-model="model.stayLoggedIn"
                label="angemeldet bleiben"
                class="mt-0"
              ></v-checkbox>
            </v-col>
            <v-col cols="6" class="text-right">
              <v-btn outlined color="primary" :loading="ui.loginInProgress" @click="login()">Anmelden</v-btn>
            </v-col>
          </v-row>
          <v-divider class="mt-10 mb-4" />
          <v-row no-gutters>
            <v-col cols="12" sm="6" class="text-left" :class="{resetpwmobile: $vuetify.breakpoint.xsOnly}">
               <v-btn text color="normal" x-small @click="ui.forgotPasswordVisible=true;pwModel.email = model.email">Passwort vergessen</v-btn>
            </v-col>
            <v-col cols="12" sm="6" class="text-right">
               <v-btn outlined color="normal" @click="state.app.registerModalActive=true">Neu Registrieren</v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <register v-if="state.app.registerModalActive" />
    <v-dialog
      v-model="ui.forgotPasswordVisible"
      max-width="600px"
    >
      <v-card>
        <v-overlay
        :absolute="true"
        :opacity="0.3"
        :value="ui.passwordForgetInProgress">
          <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
        </v-overlay>
        <v-card-title>
          <span class="headline-3">Passwort zurücksetzen / Aktivierungsmail anfordern</span>
        </v-card-title>
        <v-card-text class="mt-2">
          <v-row no-gutters>
            <v-col cols="12">
              <v-text-field
                v-model="pwModel.email"
                label="E-Mail"
                suffix="@stuttgarter.de"
                filled
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pr-4 pb-4">
          <v-spacer></v-spacer>
          <v-btn
            color="normal"
            text
            @click="ui.forgotPasswordVisible = false"
          >Abbrechen</v-btn>
          <v-btn @click="sendForgetPassword()"
            color="primary"
            outlined
            :loading="ui.passwordForgetInProgress"
            :disabled="!pwModel.email"
          >Senden</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// @ is an alias to /src
import register from '@/components/register'

export default {
  name: 'home',
  data: function() {
    return {
      state: this.$store.state,
      show1: false,
      model: {
        email: '',
        password: '',
        stayLoggedIn: false
      },
      pwModel: {
        email: ''
      },
      ui: {
        loginInProgress: false,
        forgotPasswordVisible: false,
        passwordForgetInProgress: false
      }
    }
  },
  components: {
    register
  },
  mounted() {
    console.log(this.state)
  },
  methods: {
    async login() {
      this.ui.loginInProgress = true
      await this.$sleep(300)
      let model = {
        email: this.model.email + '@stuttgarter.de',
        password: this.model.password
      }
      try {
        var result = await this.$http.post(this.state.app.apiUrl + 'api/login', model)
      } catch(err) {
        console.log(err.response)
        this.ui.loginInProgress = false
        this.$store.addToast('error', err.response ? err.response.data : 'Login fehlgeschlagen.', 7000)
        return
      }
      console.log(result)
      if (result.data) {
        this.$store.setUserObject(result.data)
      }
      this.$store.setHttpToken()
      this.$store.saveTokenToSession()
      if (this.model.stayLoggedIn)
        this.$store.saveTokenToLocalStorage()
      this.ui.loginInProgress = false
      this.$router.push({name: 'message'})
    },
    async sendForgetPassword() {
      this.ui.passwordForgetInProgress = true
      await this.$sleep(300)
      let model = {
        email: this.pwModel.email + '@stuttgarter.de'
      }
      try {
        var result = await this.$http.post(this.state.app.apiUrl + 'api/resetpassword', model)
      } catch(err) {
        //console.log(err.response)
        this.ui.passwordForgetInProgress = false
        this.$store.addToast('error', err.response ? err.response.data : 'Fehler', 7000)
        return
      }
      this.$store.addToast('blue lighten-1', "Wir senden Ihnen ein neues Passwort zu, das Sie per Klick auf einen Link bestätigen müssen.", 15000)
      this.ui.passwordForgetInProgress = false
      this.ui.forgotPasswordVisible = false
    }
  }
}
</script>

<style lang="stylus" scoped>
.resetpwmobile {
  text-align:right!important
  margin-bottom:10px
}
</style>
