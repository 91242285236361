<template>
  <v-container fluid class="home">
    <v-app-bar
      v-if="!$vuetify.breakpoint.mdAndUp"
      absolute
      color="white"
      elevate-on-scroll
      scroll-target="#scrolling-techniques-7"
    >
      <v-app-bar-nav-icon @click="state.app.mobMenuVisible = !state.app.mobMenuVisible"></v-app-bar-nav-icon>

      <v-toolbar-title></v-toolbar-title>

      <v-spacer></v-spacer>
    </v-app-bar>
    <v-navigation-drawer
      ref="drawer"
      fixed
      :permanent="$vuetify.breakpoint.mdAndUp"
      :temporary="!$vuetify.breakpoint.mdAndUp"
      v-model="state.app.mobMenuVisible"
      :expand-on-hover="$vuetify.breakpoint.mdAndUp"
      left
    >
      <template v-slot:prepend>
        <v-list-item two-line>

          <v-list-item-content style="height:90px">
            <img v-show="$refs.drawer && $refs.drawer.computedWidth > 80" width="100%" src="@/assets/STUTT_Logo_2021_RGB.png" :key="'logo1'">
            <img v-show="!$refs.drawer || !($refs.drawer.computedWidth > 80)" width="100%" src="@/assets/STUTT_Favicon_310.png" :key="'logo2'">
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-divider class="mt-3 mb-3"></v-divider>

      <v-list>
        <v-list-item color="primary" :to="{name:'message'}">
          <v-list-item-icon>
            <v-icon>mdi-message-alert</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Statusmeldung</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item color="primary" v-if="state.user.isAdmin" :to="{name:'user'}">
          <v-list-item-icon>
            <v-icon>mdi-account-multiple</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Nutzerverwaltung</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item color="primary" :to="{name:'settings'}">
          <v-list-item-icon>
            <v-icon>mdi-settings</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Einstellungen</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="logout()">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Abmelden</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <transition name="slide-left" mode="out-in">
      <router-view :class="{desktop: $vuetify.breakpoint.mdAndUp, mobile: !$vuetify.breakpoint.mdAndUp}" />
    </transition>
  </v-container>
</template>

<script>
// @ is an alias to /src
//import register from '@/components/register'

export default {
  name: 'dashboard',
  data: function() {
    return {
      state: this.$store.state,
      model: {
        statusText: '',
        resetAfter: 24
      },
      currentStatusMessage: {},
      ui: {
        createNewMsg: false,
        sendMsgInProgress: false,
        resetMsginProgress: false
      }
    }
  },
  components: {
  },
  mounted() {
  },
  computed: {
    compSendBtnDisabled() {
      if (this.model.statusText.length < 5) return true
      return false
    }
  },
  methods: {
    logout() {
      this.$store.logout()
      this.$router.push({name: 'home'})
    }
  }
}
</script>

<style lang="stylus" scoped>
.desktop {
  padding-left:80px
}
.mobile {
  margin-top:40px
}
pre {
  display: block;
  font-family: unset;
  white-space: pre;
  margin: 0;
}
.relative {
  position: relative
}
.absolute {
  position: absolute
}
.bottom {
  bottom:0
}
</style>
