import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Axios from 'axios'
import vuetify from './plugins/vuetify'
import { store } from './store'
import Storage from 'vue-ls'

const StorageSession = {...Storage}

let options = {
  namespace: 'vuejs__', // key prefix
  name: 'ls', // name variable Vue.[ls] or this.[$ls],
  storage: 'local', // storage name session, local, memory
}
Vue.use(Storage, options)

let optionsSessions = {
  namespace: 'vuejs__', // key prefix
  name: 'lsSession', // name variable Vue.[ls] or this.[$ls],
  storage: 'session', // storage name session, local, memory
}
Vue.use(StorageSession, optionsSessions)

Vue.config.productionTip = false

Vue.prototype.$store = store
Vue.prototype.$http = Axios

Vue.prototype.$sleep = function(milliseconds) {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}

const app = new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')

Vue.prototype.$store.$app = app; // <--- !!! this line adds $app to your store object
