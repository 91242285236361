<template>
  <v-card>
    <v-overlay
    :absolute="true"
    :opacity="0.3"
    :value="ui.registerInProgress">
      <v-progress-circular
      :size="50"
      color="primary"
      indeterminate
    ></v-progress-circular>
    </v-overlay>
    <v-card-title>
      <span class="headline">{{title || 'Registrierung'}}</span>
    </v-card-title>
    <v-card-text class="mt-4">
      <v-row no-gutters>
        <v-col cols="12">
          <v-text-field
            v-model="model.email"
            label="E-Mail"
            suffix="@stuttgarter.de"
            filled
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12">
          <v-text-field
            v-model="model.password"
            filled
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show1 ? 'text' : 'password'"
            label="Passwort"
            @click:append="show1 = !show1"
            autocomplete="off"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters v-if="!createAdmin">
        <v-col cols="12">
          <v-text-field
            v-model="model.email_message"
            label="E-Mail für Benachrichtigungen"
            hint="An diese E-Mail-Adresse werden Störungsmeldungen geschickt. Wir empfehlen Ihnen dafür nicht Ihre stuttgarter.de E-Mail-Adresse zu verwenden."
            filled
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters v-if="!createAdmin">
        <v-col cols="12">
          <v-text-field
            :prefix="(($refs.mobnumber && $refs.mobnumber.isFocused) || this.model.mobile_number) && '+49'"
            ref="mobnumber"
            v-model="model.mobile_number"
            :rules="[$store.mobileNumberValidation(model.mobile_number)]"
            maxlength="15"
            label="Mobiltelefonnummer"
            hint="Mobiltelefonnummer für SMS-Benachrichtigung"
            filled
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="pa-4">
      <v-spacer></v-spacer>
      <v-btn
        color="normal"
        text
        @click="state.app.registerModalActive = false"
      >Abbrechen</v-btn>
      <v-btn @click="register()"
        color="primary"
        outlined
        :disabled="compRegisterBtnDisabled"
      >{{posBtnText || 'Registrieren'}}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

export default {
  name: 'createUserForm',
  data: function() { 
    return {
      state: this.$store.state,
      show1: false,
      model: {
        email: '',
        email_message: '',
        mobile_number: '',
        password: ''
      },
      ui: {
        registerInProgress: false
      }
    }
  },
  props: {
    createAdmin: Boolean,
    title: String,
    posBtnText: String,
    successMsg: String
  },
  components: {
  },
  computed: {
    compRegisterBtnDisabled() {
      if (!this.model.email) return true
      if (!this.model.email_message && !this.model.mobile_number && !this.createAdmin) return true
      if (!this.model.password) return true
      if (this.$store.mobileNumberValidation(this.model.mobile_number)) return true
    }
  },
  mounted() {
    console.log(this.$refs.mobnumber)
  },
  methods: {
    async register() {
      this.ui.registerInProgress = true
      let model = {
        email: this.model.email + '@stuttgarter.de',
        email_message: this.model.email_message,
        mobile_number: this.model.mobile_number,
        password: this.model.password
      }
      if (this.createAdmin) {
        model.isAdmin = true
      }
      await this.$sleep(1000)
      try {
        var result = await this.$http.post(this.state.app.apiUrl + 'users', model)
      } catch(err) {
        console.log(err.response)
        this.$store.errorHandler(err)
        //this.$store.addToast('error', err.response.data, 7000)
        this.ui.registerInProgress = false
        return
      }
      this.ui.registerInProgress = false
      this.$store.addToast('blue lighten-1', this.successMsg || "Die Anmeldung war erfolgreich! Sie erhalten in den nächsten Minuten eine E-Mail von uns mit der Bitte Ihre E-Mail-Adresse zu bestätigen!", 15000)
      this.state.app.registerModalActive = false
      bus.$emit('accountCreated', result)
      return result.data
    }
  }
}
</script>
  
<style scoped>
</style>