<template>
  <v-container class="home">
    <v-row no-gutters>
      <v-col>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="users"
              :disable-pagination="false"
              :hide-default-footer="false"
              :items-per-page="15"
              :search="search"
              sort-by="id"
              class="elevation-1"
              :loading="ui.loadUserInProgress"
              loading-text="Lade Daten..."
            >
              <template v-slot:item.active="{ item }">
                <span v-if="item.active">ja</span>
                <span v-else>nein</span>
              </template>
              <template v-slot:top>
                <v-toolbar flat color="white">
                  <v-toolbar-title>Nutzerverwaltung</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Suche"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-toolbar>
              </template>
              <template v-slot:item.action="{ item }">
                <v-btn
                  @click="deleteUser(item)"
                  class="mt-2 mb-2"
                  fab
                  x-small
                  outlined
                  color="normal"
                >
                  <v-icon dark>mdi-delete</v-icon>
                </v-btn>
              </template>
              <template v-slot:item.email="{ item }">
                {{item.email}}
                <span v-if="item.isAdmin">
                  <small>(Admin)</small>
                </span>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn
              color="primary"
              outlined
              @click="state.app.registerModalActive = true"
            >Admin-Account erstellen</v-btn>
          </v-col>
        </v-row>
        <v-dialog v-model="state.app.registerModalActive" persistent max-width="600px">
          <create-user-form
            :title="'Admin erstellen'"
            :posBtnText="'Erstellen'"
            :createAdmin="true"
            :successMsg="'Der Admin-Account wurde erfolgreich erstellt. Eine Benachrichtigung findet nicht statt.'"
          />
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src
//import register from '@/components/register'
import createUserForm from "./createUserForm.vue";

export default {
  name: "User",
  data: function() {
    return {
      state: this.$store.state,
      search: "",
      headers: [
        {
          text: "ID",
          align: "left",
          sortable: true,
          value: "id"
        },
        { text: "E-Mail", value: "email" },
        { text: "Aktiv", value: "active", align: "center" },
        { text: "Actions", value: "action", sortable: false, align: "center" }
      ],
      users: [],
      ui: {
        loadUserInProgress: false
      }
    };
  },
  components: {
    createUserForm
  },
  created() {
    bus.$on("accountCreated", data => {
      console.log("account created");
      this.getUsers();
    });
  },
  async mounted() {
    this.getUsers();
  },
  computed: {},
  methods: {
    async getUsers() {
      this.ui.loadUserInProgress = true;
      await this.$sleep(300);
      try {
        let result = await this.$http.get(this.state.app.apiUrl + "Users" + "?count=1000");
        console.log(result);
        if (result.data) {
          this.users = result.data;
        }
        this.ui.loadUserInProgress = false;
      } catch (err) {
        console.log(err.response);
        this.$store.addToast("error", err.response.data, 7000);
        this.ui.loadUserInProgress = false;
        return;
      }
    },
    async deleteUser(user) {
      let msg = "Den Nutzer wirklich löschen?"
      if (user.isAdmin) {
        msg = "Den Admin wirklich löschen?"
      }
      let res = await this.$root.$confirm(
        "Wirklich löschen?",
        msg,
        "Ja",
        "Nein",
        { color: "warning" }
      );
      if (!res) {
        return;
      }
      this.ui.deleteUserInProgress = true;
      await this.$sleep(300);
      try {
        const model = {
          id: user.id
        };
        let result = await this.$http.delete(
          this.state.app.apiUrl + "Users/" + model.id,
          model
        );
        console.log(result);
        this.ui.deleteUserInProgress = false;
        this.$store.addToast("blue lighten-1", "Nutzer gelöscht.", 7000);
        this.users = this.users.filter(i => i.id !== user.id);
      } catch (err) {
        console.log(err.response);
        this.$store.addToast("error", err.response.data, 7000);
        this.ui.deleteUserInProgress = false;
        return;
      }
    }
  }
};
</script>

<style lang="stylus" scoped>
pre {
  display: block;
  font-family: unset;
  white-space: pre;
  margin: 0;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.bottom {
  bottom: 0;
}
</style>
