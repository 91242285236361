import Vue from 'vue'
const Snowflakes = require('magic-snowflakes')

const state = new Vue({
  data() {
    return {
      state: {
        app: {
          egc: 0,
          //apiUrl: location.hostname == 'localhost' ? 'http://localhost:3000/' : 'https://' + location.hostname + '/',
          apiUrl: location.hostname == 'localhost' ? 'http://localhost:3000/' : 'https://' + 'api2.status-stuttgarter.de' + '/',
          registerModalActive: false,
          mobMenuVisible: false
        },
        toasts: {
          lastId: 1,
          items: []
        },
        yolo: "yolo",
        user: {
          id: null,
          token: null,
          email: '',
          isAdmin: false
        },
        members: []
      }
    }
  },
  computed: {
    compTest() {
      console.log("jj")
      return this.state.yolo
    }
  },
  methods: {
    startSnow() {
      Snowflakes()
    },
    egc() {
      this.state.app.egc++
      if (this.state.app.egc > 5) {
        Snowflakes()
      }
    },
    setHttpToken() {
      //this.$http.defaults.headers['x-access-token'] = store.state.user.token

      this.$http.defaults.headers['authorization'] = store.state.user.token
    },
    unsetHttpToken() {
      //this.$http.defaults.headers['x-access-token'] = null
      //delete this.$http.defaults.headers.common["authorization"]
      this.$http.defaults.headers['authorization'] = ''
    },
    setUserObject(data) {
      this.state.user = {
        id: data.id,
        isAdmin: data.isAdmin,
        token: data.token
      }
    },
    saveTokenToLocalStorage() {
      this.$ls.set('user', store.state.user)
    },
    saveTokenToSession() {
      this.$lsSession.set('user', store.state.user)
    },
    removeTokenFromLocalStorage() {
      this.$ls.remove('user')
    },
    removeTokenFromSession() {
      this.$lsSession.remove('user')
    },
    addToast(type, msg, ttl) {
      this.state.toasts.lastId++
      let toastId = this.state.toasts.lastId
      this.state.toasts.items.unshift({
        id: this.state.toasts.lastId, type, msg, model: true, ttl
      })
    },
    resetUserObject() {
      this.state.user = {
        id: null,
        token: null,
        email: '',
        isAdmin: false
      }
    },
    mobileNumberValidation(number) {
      if (number) {
        if (number[0] == '0') return "Die Telefonnummer darf nicht mit 0 beginnen. Beispiel: 17620196285"
        if (number.length < 6) return "Die Telefonnummer ist zu kurz."
        if (number.length > 15) return "Die Telefonnummer ist zu lang."
        if (number.match(/\D/)) return "Nicht erlaubte Zeichen. Bitte nur Zeichen 0-9 verwenden."
        return false
      }
      return false
    },
    errorHandler(err) {
      let error = err.response.data
      let msg = err.response.data
      console.log("err", err.response.data)
      if (error.errors && error.errors[0] && error.errors[0].message) {
        msg = error.errors[0].message
        if (msg == 'email must be unique') {
          msg = 'Diese E-Mail-Adresse existiert bereits.'
        }
      }
      this.addToast('error', msg, 9000)
    },
    logout() {
      console.log("LOGOUT")
      this.removeTokenFromLocalStorage()
      this.removeTokenFromSession()
      this.resetUserObject()
      this.unsetHttpToken()
    }
  }
})

export const store = state

/*
export const store = {
  state: {
    app: {
      apiUrl: 'http://localhost:3000/',
      registerModalActive: false
    },
    toasts: {
      lastId: 1,
      items: []
    },
    yolo: "yolo",
    user: {
      id: null,
      token: null
    },
    members: [],
    test() {
      console.log("yolo function called")
      return store.state.yolo
    }
  },
  methods: {
    checkLogin() {
      if (store.state.user.id && store.state.user.token) return true
      else {
        let s = store.$app.$ls.get('user')
        if (s && s.id && s.token) {
          store.state.user.id = s.id
          store.state.user.token = s.token
          store.state.user.admin = s.admin
          return true
        }
      }
      return false
    },
    invalidateLogin() {
      store.state.user.id = null
      store.state.user.token = null
    },
    addToast(type, msg, ttl) {
      store.state.toasts.lastId++
      let toastId = store.state.toasts.lastId
      //füge toast zum array hinzu und lösche es dann mit setTimeout heraus
      store.state.toasts.items.unshift({
        id: store.state.toasts.lastId, type, msg
      })
      setTimeout(() => {
        store.methods.removeToast(toastId)
      }, ttl)
    },
    removeToast(toastId) {
      store.state.toasts.items = store.state.toasts.items.filter((item) => {
        return item.id !== toastId
      })
    },
    setHttpToken() {
      store.$app.$http.defaults.headers['x-access-token'] = store.state.user.token
    },
    unsetHttpToken() {
      store.$app.$http.defaults.headers['x-access-token'] = null
    },
    saveTokenToLocalStorage() {
      store.$app.$ls.set('user', store.state.user)
    },
    removeTokenFromLocalStorage() {
      store.$app.$ls.remove('user')
    },
    deleteFromMembers(id) {
      store.state.members = store.state.members.filter((i) => {
        return i.id !== id
      })
    },
    test() {

    }
  }
}

*/