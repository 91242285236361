<template>
  <v-container class="home">
    <v-row no-gutters>
      <v-col>
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-overlay :absolute="true" :opacity="0.3" :value="ui.loadUserInProgress">
                <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
              </v-overlay>
              <v-card-title class="headline">Einstellungen</v-card-title>
              <v-divider class></v-divider>
              <v-card-text>
                <v-row class="mt-4">
                  <v-col cols="12" xl="4" lg="6" md="8" sm="12" class="mb-0 mt-0 pt-0 pb-0">
                    <v-text-field
                      v-model="model.email"
                      label="E-Mail"
                      suffix="@stuttgarter.de"
                      filled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" xl="4" lg="6" md="8" sm="12" class="mb-0 mt-0 pt-0 pb-0">
                    <v-text-field
                      v-model="model.email_message"
                      label="E-Mail für Benachrichtigungen"
                      hint="An diese E-Mail-Adresse werden Störungsmeldungen geschickt. Wir empfehlen Ihnen dafür nicht Ihre stuttgarter.de E-Mail-Adresse zu verwenden."
                      filled
                      autocomplete="off"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" xl="4" lg="6" md="8" sm="12" class="mb-0 mt-0 pt-0 pb-0">
                    <v-text-field
                      v-model="model.mobile_number"
                      :prefix="(($refs.mobnumber && $refs.mobnumber.isFocused) || this.model.mobile_number) && '+49'"
                      ref="mobnumber"
                      maxlength="15"
                      label="Mobiltelefonnummer"
                      :rules="[$store.mobileNumberValidation(model.mobile_number)]"
                      hint="Mobiltelefonnummer für SMS-Benachrichtigung"
                      filled
                      autocomplete="off"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="mb-0 mt-0 pt-0 pb-0">
                    <v-row>
                      <v-col cols="auto" class="mb-0 mt-0 pt-0 pb-0">
                        <v-checkbox class="mt-0" v-model="model.get_email" label="Statusmeldung per E-Mail"></v-checkbox>
                      </v-col>
                      <v-col cols="auto" class="mb-0 mt-0 pt-0 pb-0">
                        <v-checkbox class="mt-0" v-model="model.get_sms" label="Statusmeldung per SMS"></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" xl="4" lg="6" md="8" sm="12" class="mb-0 mt-0 pt-0 pb-0">
                    <v-text-field
                      v-model="model.password"
                      filled
                      autocomplete="off"
                      :append-icon="ui.showpw1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="ui.showpw1 ? 'text' : 'password'"
                      label="Neues Passwort"
                      @click:append="ui.showpw1 = !ui.showpw1"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    xl="4"
                    lg="6"
                    md="8"
                    sm="12"
                    class="mb-0 mt-0 pt-0 pb-0"
                    v-if="model.password"
                  >
                    <v-text-field
                      ref="pwConfirm"
                      v-model="model.password2"
                      filled
                      :rules="[passwordsNotEqual]"
                      :append-icon="ui.showpw2 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="ui.showpw2 ? 'text' : 'password'"
                      label="Passwort Wiederholung"
                      @click:append="ui.showpw2 = !ui.showpw2"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions class="mt-3">
                <v-row no-gutters>
                  <v-col cols="12" align="right">
                    <v-btn
                      small
                      text
                      @click="cancel()"
                      color="normal"
                      :disabled="!formChanged"
                    >Abbrechen</v-btn>
                    <v-btn
                      small
                      outlined
                      @click="saveUser()"
                      color="primary"
                      :disabled="!formChanged || !formValid"
                    >Speichern</v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src
//import register from '@/components/register'

export default {
  name: "Settings",
  data: function() {
    return {
      state: this.$store.state,
      user: {},
      model: {
        email: "",
        email_message: "",
        mobile_number: "",
        get_email: false,
        get_sms: false,
        password: null,
        password2: null
      },
      modelCopy: {},
      ui: {
        loadUserInProgress: false,
        showpw1: false,
        showpw2: false
      }
    };
  },
  components: {},
  computed: {
    formChanged() {
      return !this.isEqual(this.model, this.modelCopy);
    },
    formValid() {
      if (this.model.password) {
        if (this.model.password !== this.model.password2) return false
      }
      if (this.$store.mobileNumberValidation(this.model.mobile_number)) return false
      return true;
    },
    passwordsNotEqual() {
      if (this.model.password != this.model.password2) {
        return "Passwörter stimmen nicht überein";
      }
      return false;
    }
  },
  async mounted() {
    this.getUser()
  },
  methods: {
    isEqual(a, b) {
      return JSON.stringify(a) === JSON.stringify(b);
    },
    async getUser() {
      this.ui.loadUserInProgress = true;
      //await this.$sleep(300)
      try {
        let result = await this.$http.get(
          this.state.app.apiUrl + "Users/" + this.state.user.id
        );
        console.log(result);
        if (result.data) {
          this.model = result.data;
          this.model.email = this.model.email.split("@")[0];
          this.modelCopy = { ...this.model };
        }
        this.ui.loadUserInProgress = false;
      } catch (err) {
        console.log(err.response);
        this.$store.addToast("error", err.response.data, 7000);
        this.ui.loadUserInProgress = false;
        return;
      }
    },
    async saveUser() {
      this.ui.loadUserInProgress = true;
      await this.$sleep(300);
      let model = { ...this.model };
      model.email = model.email + "@stuttgarter.de";
      try {
        let result = await this.$http.put(
          this.state.app.apiUrl + "Users/" + this.state.user.id,
          model
        );
        console.log(result);
        this.ui.loadUserInProgress = false;
        this.modelCopy = { ...this.model };
        this.$store.addToast("blue lighten-1", "Änderungen gespeichert.", 7000);
      } catch (err) {
        console.log(err);
        this.$store.errorHandler(err)
        this.ui.loadUserInProgress = false;
        return;
      }
    },
    async cancel() {
      this.model = { ...this.modelCopy };
    },
    async deleteUser(user) {
      let res = await this.$root.$confirm(
        "Wirklich löschen?",
        "Den Nutzer wirklich löschen?",
        "Ja",
        "Nein",
        { color: "warning" }
      );
      if (!res) {
        return;
      }
      this.ui.deleteUserInProgress = true;
      await this.$sleep(300);
      try {
        const model = {
          id: user.id
        };
        let result = await this.$http.delete(
          this.state.app.apiUrl + "Users/" + model.id,
          model
        );
        console.log(result);
        this.ui.deleteUserInProgress = false;
        this.$store.addToast("blue lighten-1", "Nutzer gelöscht.", 7000);
        this.users = this.users.filter(i => i.id !== user.id);
      } catch (err) {
        console.log(err.response);
        this.$store.addToast("error", err.response.data, 7000);
        this.ui.deleteUserInProgress = false;
        return;
      }
    }
  }
};
</script>

<style lang="stylus" scoped></style>
