 <template>
  <v-dialog
    v-model="state.app.registerModalActive"
    persistent
    max-width="600px"
  >
    <create-user-form :title="'Anmelden'" :createAdmin="false" />
  </v-dialog>
</template>
  
<script>
import createUserForm from './createUserForm.vue'

export default {
  name: 'register',
  data: function() { 
    return {
      state: this.$store.state
    }
  },
  components: {
    createUserForm
  },
  computed: {
    
  },
  methods: {
    
  }
}
</script>
  
<style scoped>
</style>