<template>
  <v-container class="home">
    <v-row no-gutters>
      <v-col>
        <v-row style="border:0px solid black">
          <v-col>
            <v-card>
              <v-overlay :absolute="true" :opacity="0.3" :value="ui.sendMsgInProgress">
                <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
              </v-overlay>
              <v-card-title class="headline">Aktuelle Statusmeldung</v-card-title>
              <v-divider class></v-divider>
              <v-card-text class="mb-8">
                <div v-if="ui.createNewMsg">
                  <v-textarea
                    filled
                    counter
                    auto-focus
                    auto-grow
                    name="input-7-4"
                    label="Statusmeldung"
                    v-model="model.statusText"
                  ></v-textarea>
                  <div v-if="numberOfReceivers">
                    Anzahl versendeter SMS-Nachrichten bei dieser Nachricht: {{compNumberOfSMS}}
                    <div>
                    <small>(je {{compNumberOfSMSperUser}} SMS an {{numberOfReceivers}} Empfänger)</small>
                    </div>
                  </div>
                </div>
                <div v-else class="body-1">
                  <div v-if="currentStatusMessage.text">
                    <pre>{{currentStatusMessage.text}}</pre>
                  </div>
                  <div v-else>Derzeit gibt es keine Störungsmeldungen.</div>
                </div>
              </v-card-text>
              <v-card-actions class="mt-3" v-if="state.user.isAdmin">
                <v-row no-gutters>
                  <v-col cols="12" md="6" class="relative" style="align-self: flex-end">
                    <div class>
                      <v-row dense>
                        <v-col cols="auto">
                          <v-btn small outlined @click="ui.createNewMsg = !ui.createNewMsg">
                            <span v-if="ui.createNewMsg">Abbrechen</span>
                            <span v-else>Neue Nachricht erstellen</span>
                          </v-btn>
                        </v-col>
                        <v-col cols="auto">
                          <v-btn
                            small
                            outlined
                            :loading="ui.resetMsgInProgress"
                            :disabled="!currentStatusMessage.id"
                            v-if="!ui.createNewMsg"
                            @click="resetMsg()"
                          >Aktuelle Meldung zurücksetzen</v-btn>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                  <v-col cols="12" md="6" align="right">
                    <div v-if="ui.createNewMsg">
                      <div class="mt-1 body-2 mb-2">
                        Zurücksetzen nach
                        <v-text-field
                          class="pt-0 pb-0 pl-1 pr-0 ma-0"
                          hide-details
                          style="width:40px;display:inline-block"
                          type="number"
                          min="0"
                          max="10000"
                          v-model="model.resetAfter"
                        ></v-text-field>Stunden
                      </div>
                      <div>
                        <v-row dense style="justify-content:flex-end">
                          <v-col cols="auto">
                            <v-btn
                              small
                              outlined
                              color="primary"
                              @click="sendMsg(true)"
                              :disabled="compSendBtnDisabled"
                            >Ohne Benachrichtigung senden</v-btn>
                          </v-col>
                          <v-col cols="auto">
                            <v-btn
                              small
                              color="primary"
                              @click="sendMsg(false)"
                              :disabled="compSendBtnDisabled"
                            >Abschicken</v-btn>
                          </v-col>
                        </v-row>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src
//import register from '@/components/register'

export default {
  name: "message",
  data: function() {
    return {
      state: this.$store.state,
      model: {
        statusText: "",
        resetAfter: 24
      },
      numberOfReceivers: 0,
      currentStatusMessage: {},
      ui: {
        createNewMsg: false,
        sendMsgInProgress: false,
        resetMsginProgress: false
      }
    };
  },
  components: {},
  mounted() {
    this.getCurrentStatusMessage()
    if (this.state.user.isAdmin) this.getNumberOfReceivers()
  },
  computed: {
    compSendBtnDisabled() {
      if (this.model.statusText.length < 5) return true;
      return false;
    },
    compNumberOfSMSperUser() {
      return Math.ceil(this.model.statusText.length / 160)
    },
    compNumberOfSMS() {
      return Math.ceil(this.model.statusText.length / 160) * this.numberOfReceivers
    }
  },
  methods: {
    async getCurrentStatusMessage() {
      try {
        let result = await this.$http.post(
          this.state.app.apiUrl + "api/getMessage"
        )
        console.log(result)
        if (result.data) {
          this.currentStatusMessage = result.data
        }
      } catch (err) {
        console.log(err.response)
        this.$store.addToast("error", err.response.data, 7000)
        return
      }
    },
    async getNumberOfReceivers() {
      try {
        let result = await this.$http.post(
          this.state.app.apiUrl + "api/countSmsReceivers"
        )
        if (result.data) {
          this.numberOfReceivers = result.data
        }
      } catch (err) {
        console.log(err.response)
        return
      }
    },
    async resetMsg() {
      let res = await this.$root.$confirm(
        "Bestätigen",
        "Soll die Nachricht wirklich zurückgesetzt werden?",
        "Ja",
        "Nein",
        { color: "warning" }
      );
      if (!res) return;
      this.ui.resetMsginProgress = true;
      if (!this.currentStatusMessage.id) return;
      await this.$sleep(200);
      const model = {
        id: this.currentStatusMessage.id
      };
      try {
        let result = await this.$http.post(
          this.state.app.apiUrl + "api/resetMessage",
          model
        );
        this.ui.resetMsginProgress = false;
        this.$store.addToast(
          "blue lighten-1",
          "Die Statusmeldung wurde zurückgesetzt",
          7000
        );
        this.currentStatusMessage = {};
      } catch (err) {
        console.log(err.response);
        this.$store.addToast("error", err.response.data, 7000);
        this.ui.resetMsginProgress = false;
        return;
      }
    },
    async sendMsg(withoutNotification) {
      this.ui.sendMsgInProgress = true;
      await this.$sleep(500);
      const model = {
        message: this.model.statusText,
        hours: this.model.resetAfter,
        withoutNotification: withoutNotification
      };
      try {
        let result = await this.$http.post(
          this.state.app.apiUrl + "api/sendMessage",
          model
        );
      } catch (err) {
        console.log(err.response);
        this.$store.addToast("error", err.response.data, 7000);
        this.ui.sendMsgInProgress = false;
        return;
      }
      this.ui.sendMsgInProgress = false;
      if (withoutNotification) {
        this.$store.addToast(
          "blue lighten-1",
          "Die Statusmeldung wurde erfolgreich gespeichert. Ein Versand an die Mitglieder findet nicht statt.",
          20000
        );
      } else {
        this.$store.addToast(
          "blue lighten-1",
          "Die Statusmeldung wurde erfolgreich gespeichert. Sie wird im Hintergrund vom Server an die Mitglieder verschickt.",
          20000
        );
      }
      await this.getCurrentStatusMessage();
      this.ui.createNewMsg = false;
      this.model.statusText = "";
    },
    logout() {
      this.$store.logout();
      this.$router.push({ name: "home" });
    }
  }
};
</script>

<style lang="stylus" scoped>
pre {
  display: block;
  font-family: unset;
  white-space: pre;
  margin: 0;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.bottom {
  bottom: 0;
}
</style>
